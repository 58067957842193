import * as courseBuilder from './courseBuilder'
import * as lessonBuilder from './lessonBuilder'
import * as menu from './menu'
import * as loading from './loading'
import * as user from './user'
import * as courses from './courses'
import * as organizations from './organizations'
import * as annotation from './annotation'
import * as roles from './roles'
import * as quizes from './quizes'
import * as library from './library'
import * as reports from './reports'
import * as assessments from './assessments'
import * as discussion from './discussion'
import * as topics from './topics'
import * as events from './events'
import * as coursesEvents from './coursesEvents'
import * as audioSynthesizer from './audioSynthesizer'
import * as pageBuilder from './pageBuilder'
import * as notes from './notes'
import * as badge from './badge'
import * as mazeBuilder from './mazeBuilder'
import * as maze from './maze'
import * as skillJourney from './skillJourney'
import * as trivia from './trivia'
import * as podcast from './podcast'

export default {
  courseBuilder,
  lessonBuilder,
  menu,
  loading,
  user,
  courses,
  organizations,
  annotation,
  roles,
  quizes,
  library,
  reports,
  assessments,
  discussion,
  topics,
  events,
  coursesEvents,
  audioSynthesizer,
  pageBuilder,
  notes,
  badge,
  mazeBuilder,
  maze,
  skillJourney,
  trivia,
  podcast
}
