import React, { Component, Fragment } from 'react'
import { Spinner, Modal } from '../../../../../components'
import Template from '../Template'
import { Annotation } from './styled'
import { PageContent, PageWrapper, AnnotationPageWrapper } from '../../styled'

import api from '../../../../../services/api'

let timeOutCallAnnotation = null

class AnnotationView extends Component {
  state = {
    showModal: false,
    annotationContent: null,
    /*pageSize: {
      height: 552,
      width: 1024
    },*/
    pageSize: {
      width: 768,
      height: 1024
    },
    scale: 1
  }

  modalInner = null
  pageContent = null
  dontLoad = false

  setModalInnerRef = el => {
    this.modalInner = el
    this.doResize()
  }

  setPageContentRef = el => {
    this.pageContent = el
    this.doResize()
  }

  doResize = () => {
    if (this.pageContent && this.modalInner) {
      const contentWidth = this.pageContent.clientWidth
      const contentHeight = this.pageContent.clientHeight
      const wrapperWidth = this.modalInner.clientWidth
      const wrapperHeight = this.modalInner.clientHeight

      let scale = Math.min(
        wrapperWidth / contentWidth,
        wrapperHeight / contentHeight
      )

      if (scale > 1) scale = 1
      this.setState({ scale })
    }
  }

  componentDidMount() {
    const id = this.props.content
    if (!id) {
      return
    }
    timeOutCallAnnotation = setTimeout(() => {
      if (this.dontLoad) return
      api.annotation.getById(id).then(({ data }) => {
        if (data) {
          this.setState({
            annotationContent: data.page.data
            // pageSize: data.page.size
          })
        }
      })
    }, 1000)
  }

  componentWillUnmount() {
    this.dontLoad = true
    clearTimeout(timeOutCallAnnotation)
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  render() {
    const { content, ids, isFullscreen, ...rest } = this.props
    const { showModal, annotationContent, pageSize, scale } = this.state

    return (
      <Fragment>
        <Annotation onClick={this.openModal} {...rest} />
        <Modal
          width="1074px"
          visible={showModal}
          onCancel={this.closeModal}
          destroyOnClose
          zIndex={1000}
          getContainer={!isFullscreen}
          centered
        >
          <AnnotationPageWrapper
            scale={scale}
            ref={this.setModalInnerRef}
            size={pageSize}
            style={{
              paddingTop: '5rem'
            }}
          >
            {annotationContent ? (
              annotationContent.map(page => (
                <Template key={page.uid} node={page} ids={ids} />
              ))
            ) : (
              <Spinner size="small" />
            )}
          </AnnotationPageWrapper>
        </Modal>
      </Fragment>
    )
  }
}

export default AnnotationView
