import http from './http'
export const generateConversation = (payload) => {
    return http({
        method: 'POST',
        data: payload,
        url: `/podcastai/content`
    })
}
export const generatePodcastAudio = (payload) => {
    return http({
        method: 'POST',
        data: payload,
        url: `/podcastai/tts`
    })
}
export const createVoice = (payload) => {
    return http({
        method: 'POST',
        data: payload,
        url: `/podcastai/voices`
    })
}
export const getAllVoices = () => {
    return http({
        method: 'GET',
        url: `/podcastai/voices`
    })
}

export const checkPodcastStatus = (id) => {
    return http({
        method: 'GET',
        url: `library/${id}/info`
    })
}