import React, { Fragment, useEffect, useState } from 'react'
import {
  Form,
  Button,
  Select,
  Input,
  Tooltip,
  Row,
  Col,
  Tabs,
  List,
  Avatar,
  message,
  Modal
} from 'antd'
import {
  SoundOutlined,
  PlusCircleOutlined,
  LeftOutlined
} from '@ant-design/icons'
import './DiscussionCreateMachine.scss'
import { useTranslation } from 'react-i18next'
import { ImageSelect } from '../../../../components/Form'
import FileUploadComponent from '../components/UploadFiles/UploadFiles'
import './DiscussionCreateMachine.scss'
import actions from '../../../../store/actions'
import { useDispatch } from 'react-redux'
import ReactPlayer from 'react-player'
import { AudioIcon } from '../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/components/Icons'
import FooterActions from '../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/components/FooterActions'
import Audio from '../../../Courses/Create/nestedComponents/PageBuilder/Components/Audio'
import _ from 'lodash'
import { useRef } from 'react'

const { Option } = Select
const { TabPane } = Tabs
const DiscussionCreateMachine = ({ form, open, onFinish, close }) => {
  const audioRef = useRef(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [step, setStep] = useState(0)
  const [tabIndex, setTabIndex] = useState('1')
  const [audioUrl, setAudioUrl] = useState({
    id: '',
    link: '',
    filename: ''
  })
  const [sampleAudioUrl, setSampleAudioUrl] = useState('')
  const [voiceList, setVoiceList] = useState([{ name: '', voice_id: '' }])
  const [allEpisode, setEpisode] = useState([
    { content: [], speakers: [], title: '' }
  ])
  const [formValues, setFormValues] = useState({
    cover: { id: '', link: '', file_size: '', filename: '' },
    cover_id: '',
    title: '',
    description: '',
    speakers: null,
    episode: 1,
    length: '',
    host: '',
    tone: '',
    files: [],
    guests: ['kismat']
  })
  const [attachedData, setAttachedData] = useState({
    filenames: [],
    attachmentIds: []
  })
  const [contents, setContents] = useState([])
  const [speakerList, setSpeakerList] = useState([])
  const [isEdit, setIsEdit] = useState({
    status: false,
    context: '',
    index: null
  })
  const [isSaving, setIsSaving] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [openAudioModal, setOpenAudioModal] = useState({ open: false, id: '' })
  const [selectedAudio, setSelectedAudio] = useState({
    id: '',
    audio_url: '',
    name: ''
  })
  const [isVoiceLoading, setIsVoiceLoading] = useState(false)
  const {
    getFieldDecorator,
    validateFields,
    getFieldsValue,
    setFieldsValue
  } = form
  useEffect(() => {
    getAllVoice()
  }, [])
  useEffect(() => {
    setFieldsValue({
      files: attachedData.attachmentIds
    })
    // setFormValues({ ...formValues, files: attachedData.attachmentIds })
  }, [attachedData.attachmentIds])

  useEffect(() => {
    if (audioRef.current && sampleAudioUrl) {
      audioRef.current.pause()
      audioRef.current.load()
      audioRef.current.play().catch(error => {
        console.error('Audio playback failed', error)
      })

      const handleEnded = () => {
        audioRef.current.currentTime = 0
        setSampleAudioUrl('')
      }
      audioRef.current.addEventListener('ended', handleEnded)
      return () => {
        audioRef.current.removeEventListener('ended', handleEnded)
      }
    }
  }, [sampleAudioUrl])
  const getAllVoice = async () => {
    const res = await dispatch(actions.podcast.getAllVoices())
    if (res.data && res.status <= 300 && res.status >= 200) {
      setVoiceList(res.data.voices)
    }
  }
  const previous = () => {
    setFieldsValue({
      cover_id: formValues.cover_id,
      title: formValues.title,
      description: formValues.description,
      episode: formValues.episode,
      speakers: formValues.speakers,
      guests: formValues.guests,
      host: formValues.host,
      length: formValues.length,
      tone: formValues.tone,
      files: attachedData.attachmentIds
    })
    let speakerList = []
    formValues.guests.forEach(name => {
      speakerList.push({ speaker_name: name })
    })
    setSpeakerList(speakerList)
    setStep(0)
    setAudioUrl({ id: '', ink: '', filename: '' })
  }
  const checkContentStatus = async id => {
    try {
      const res = await dispatch(actions.podcast.checkPodcastStatus(id))
      if (res.data && res.status >= 200 && res.status <= 300) {
        if (res.data.link) {
          try {
            const response = await fetch(res.data.link)
            if (!response.ok) {
              throw new Error('Could not fetch content!')
            }

            const jsonData = await response.json()
            const episode = [jsonData]

            setEpisode(episode)
            setContents(episode[0].content)
            setStep(1)
          } catch (error) {
            message.error(error.message)
          } finally {
            setIsGenerating(false)
          }
        } else {
          setTimeout(() => {
            checkContentStatus(id)
          }, 7000)
        }
      } else {
        throw new Error('Failed to generate content!')
      }
    } catch (error) {
      message.error(error.message)
      setIsGenerating(false)
    }
  }
  const generateContent = async () => {
    validateFields().then(async values => {
      let data = {
        title: values.title,
        description: values.description,
        speakers: values.guests,
        files: attachedData.attachmentIds,
        length: values.length,
        tone: values.tone
      }
      setIsGenerating(true)
      try {
        let res = await dispatch(actions.podcast.generateConversation(data))
        if (res.data && res.status <= 300 && res.status >= 200) {
          checkContentStatus(res.data.id)
          setFormValues({
            ...formValues,
            title: values.title,
            description: values.description,
            episode: values.episode || 1,
            speakers: values.speakers,
            guests: values.guests,
            host: values.host,
            length: values.length,
            tone: values.tone
          })
        } else {
          message.error('Failed to generate content!')
          setIsGenerating(false)
        }
      } catch (error) {
        setIsGenerating(false)
        message.error('Failed to generate podcast content!')
      }
    })
  }

  const setCoverImage = cover => {
    setFieldsValue({
      cover_id: cover.id
    })
    setFormValues({ ...formValues, cover: cover, cover_id: cover.id })
  }
  const changeHostName = e => {
    let name = e.target.value
    setSpeakerList(prevSpeakerList => {
      const updatedSpeakerList = [...prevSpeakerList]
      if (updatedSpeakerList.length > 0) {
        updatedSpeakerList[0] = {
          ...updatedSpeakerList[0],
          speaker_name: name
        }
      }
      return updatedSpeakerList
    })
    setFieldsValue({ 'guests[0]': name })
  }
  const handleChangeSpeaker = number => {
    let hostname = getFieldsValue().host
    let speakerList = []
    for (let i = 1; i <= number; i++) {
      if (i === 1) {
        speakerList.push({ speaker_name: hostname })
      } else {
        speakerList.push({ speaker_name: '' })
      }
    }
    setSpeakerList(speakerList)
  }
  const episodeChanage = index => {
    setTabIndex(index)
  }
  const listenSample = speaker => {
    let voice = { preview_url: '' }
    voice = voiceList.find(
      el => el.voice_id === getFieldsValue()[`${speaker}_voice`]
    )
    if (voice.preview_url) {
      setSampleAudioUrl(voice.preview_url)
    } else {
      message.warn('There is audio sample for this voice!')
    }
  }
  const updateContext = index => {
    setAudioUrl({ id: '', ink: '', filename: '' })
    setContents(prevContents => {
      const newContents = [...prevContents]
      newContents[index].context = isEdit.context
      return newContents
    })
    setIsEdit({ index: -1, status: false, contents: '' })
  }
  const handleInputChange = e => {
    const value = e.target.value
    setIsEdit({ ...isEdit, context: value })
  }
  const generateAudio = async episode => {
    let voices = []
    let speakers = []
    allEpisode.forEach((el, i) => {
      if (i === episode) {
        el.speakers.forEach(speaker => {
          speakers.push(speaker)
          voices.push({
            [speaker]: getFieldsValue()[`${speaker}_voice`]
          })
        })
      }
    })
    const objectVoices = voices.reduce((acc, obj) => {
      return { ...acc, ...obj }
    }, {})
    let payload = {
      title: formValues.title,
      speakers: speakers,
      voices: objectVoices,
      content: contents
    }
    setIsGenerating(true)
    setAudioUrl({ id: '', ink: '', filename: '' })
    const res = await dispatch(actions.podcast.generatePodcastAudio(payload))
    if (res.data && res.status <= 300 && res.status >= 200) {
      checkPodcastStatus(res.data.id)
    } else {
      message.error('Failed to generate audio!')
      setIsGenerating(false)
    }
  }
  const checkPodcastStatus = async id => {
    const res = await dispatch(actions.podcast.checkPodcastStatus(id))
    if (res.data && res.status <= 300 && res.status >= 200) {
      if (res.data.link) {
        setAudioUrl({
          id: res.data.id,
          link: res.data.link,
          filename: res.data.filename
        })
        setIsGenerating(false)
      } else {
        setTimeout(() => {
          checkPodcastStatus(id)
        }, 10000)
      }
    } else {
      setIsGenerating(false)
      message.error('Failed to generate audio!')
    }
  }
  const createPodcastEpishode = async () => {
    setIsSaving(true)
    onFinish({
      id: audioUrl.id,
      filename: audioUrl.filename,
      cover_id: formValues.cover_id,
      title: formValues.title,
      description: formValues.description
    })
    setTimeout(() => {
      setIsSaving(false)
    }, 300)
  }
  const createVoice = async payload => {
    setIsVoiceLoading(true)
    const res = await dispatch(actions.podcast.createVoice(payload))
    if (res.data && res.status <= 300 && res.status >= 200) {
      let tempVoice = [...voiceList]
      tempVoice.push(res.data)
      setVoiceList(tempVoice)
      setIsVoiceLoading(false)
      setOpenAudioModal({ ...openAudioModal, open: false })
      setFieldsValue({ [openAudioModal.id + '_voice']: res.data.voice_id })
    } else {
      setIsVoiceLoading(false)
      message.error('Failed to create voice!')
    }
  }
  const onShowCreateModal = () => {
    console.log('test create')
  }
  const getSelectedFile = files => {
    let file = files[0]
    setSelectedAudio({ id: file.id, audio_url: file.link, name: file.filename })
  }

  const handleInsertData = ({ rows: privateData }, publicMedia, orgMedia) => {
    let tempData = privateData
    if (publicMedia) {
      const { rows: publicData } = publicMedia
      tempData = [...privateData, ...publicData]
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      tempData = [...privateData, ...orgData]
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))
    setSelectedAudio({
      id: selectedItem.id,
      audio_url: selectedItem.link,
      name: selectedItem.filename
    })
  }
  return (
    <Modal
      title={'Generate Podcast Audio'}
      visible={open}
      onCancel={close}
      maskClosable={false}
      footer={[
        step === 0 && (
          <Fragment>
            <Tooltip placement="left" title={''} arrow>
              <span>
                <Button
                  type="primary"
                  className="rounded"
                  loading={isGenerating}
                  onClick={generateContent}
                  disabled={isUploading}
                >
                  Generate to text
                </Button>
              </span>
            </Tooltip>
          </Fragment>
        ),
        step === 1 && (
          <Fragment>
            <Tooltip placement="left" title={''} arrow>
              <span>
                <Button
                  type="primary"
                  className="rounded"
                  onClick={() => {
                    previous()
                  }}
                  disabled={isGenerating}
                >
                  <LeftOutlined /> Go Back
                </Button>
              </span>
            </Tooltip>
            &nbsp;
            <Tooltip placement="left" title={''} arrow>
              <span>
                <Button
                  type="primary"
                  className="rounded"
                  loading={isSaving}
                  disabled={!audioUrl.link}
                  onClick={createPodcastEpishode}
                >
                  Create Podcast Episode
                </Button>
              </span>
            </Tooltip>
          </Fragment>
        )
      ]}
      centered
      width="85vw"
      className="full-width-modal"
    >
      <audio ref={audioRef} src={sampleAudioUrl} autoPlay />
      <div className="discussion-machine-main">
        <div className="discussion-machine-main-body">
          <Form layout="vertical">
            {step === 0 && (
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={16} lg={14}>
                  <Form.Item
                    required={false}
                    colon={false}
                    className="no_bottom_margin"
                  >
                    {getFieldDecorator('cover_id', {
                      initialValue: formValues.cover.id,
                      rules: [
                        {
                          required: true,
                          message: 'please upload podcast cover!'
                        }
                      ]
                    })(
                      <ImageSelect
                        label={'Cover Picture'}
                        coverInput={
                          formValues.cover.id ? formValues.cover : null
                        }
                        onCoverChange={setCoverImage}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label="Name of Podcast"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('title', {
                      initialValue: formValues.title || '',
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the podcast name!'
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item
                    label="Podcast Description"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('description', {
                      initialValue: formValues.description || '',
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the podcast desctiption!'
                        }
                      ]
                    })(<Input.TextArea placeholder={'Description'} rows={3} />)}
                  </Form.Item>
                  <Row gutter={[8, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Number of Speakers"
                        colon={false}
                        required={false}
                      >
                        {getFieldDecorator('speakers', {
                          initialValue: formValues.speakers || undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please select the number of speakers!'
                            }
                          ]
                        })(
                          <Select size="large" onChange={handleChangeSpeaker}>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                    <Form.Item
                      label="Number of Episodes"
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('episode', {
                        initialValue: formValues.episode || undefined,
                        rules: [
                          {
                            required: true,
                            message: 'Please input number of episode!'
                          }
                        ]
                      })(<Input type="number" disabled />)}
                    </Form.Item>
                  </Col> */}
                    <Col span={12}>
                      <Form.Item
                        label="Podcast Length"
                        colon={false}
                        required={false}
                      >
                        {getFieldDecorator('length', {
                          initialValue: formValues.length || '',
                          rules: [
                            {
                              required: true,
                              message: 'Please enter the length of the podcast!'
                            }
                          ]
                        })(
                          <Select size="large">
                            <Option value={'short'}>Short (3-4 min)</Option>
                            <Option value={'medium'}>Medium (4-6 min)</Option>
                            <Option value={'long'}>Long (5+ min)</Option>
                          </Select>
                        )}
                        {/* {getFieldDecorator('length', {
                        initialValue: formValues.length || '',
                        rules: [
                          {
                            required: true,
                            message: 'Please enter the length of the podcast!'
                          }
                        ]
                      })(<Input placeholder="e.g., 15" type="number" />)} */}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label="Name of the Host"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('host', {
                      initialValue: formValues.host || '',
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the host name!'
                        }
                      ]
                    })(<Input onChange={changeHostName} />)}
                  </Form.Item>
                  {speakerList.length >= 1 && (
                    <Form.Item
                      label="Name of guests"
                      colon={false}
                      required={false}
                    >
                      {speakerList.map((speaker, i) => (
                        <Form.Item
                          key={i}
                          colon={false}
                          required={false}
                          style={{ marginBottom: 0 }} // Removed '!important' since it's not valid in inline styles
                        >
                          {getFieldDecorator(`guests[${i}]`, {
                            initialValue: speaker.speaker_name || '',
                            rules: [
                              {
                                required: true,
                                message: 'Please enter the guest name!'
                              }
                            ]
                          })(
                            <Input
                              addonBefore={`No. ${i + 1} :`}
                              disabled={i === 0}
                            />
                          )}
                        </Form.Item>
                      ))}
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Choose tone of speaking"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('tone', {
                      initialValue: formValues.tone || undefined,
                      rules: [
                        {
                          required: true,
                          message: 'Please select the tone of the podcast!'
                        }
                      ]
                    })(
                      <Select size="large">
                        <Option value="serious">Serious</Option>
                        <Option value="light humor">Light Humor</Option>
                        <Option value="casual">Casual</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={10}>
                  <Form.Item
                    label={
                      <div style={{ display: 'flex', textTransform: 'none' }}>
                        Upload Files (word files, pdf)
                        <div
                          style={{
                            marginLeft: 10,
                            background: 'red',
                            color: '#fff',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            height: 20,
                            width: 20
                          }}
                        >
                          {attachedData.filenames.length}
                        </div>
                      </div>
                    }
                    colon={false}
                    required={false}
                    className="no_bottom_margin"
                  >
                    {getFieldDecorator('files', {
                      initialValue: attachedData.filenames || undefined,
                      rules: [
                        {
                          required: true,
                          message: 'Please add file(s)!'
                        }
                      ]
                    })(
                      <FileUploadComponent
                        setIsLoading={setIsUploading}
                        ids={attachedData.attachmentIds}
                        data={attachedData}
                        setData={setAttachedData}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {step === 1 && (
              <Fragment>
                <Modal
                  title={<AudioIcon t={t} />}
                  className="custom-ant-modal"
                  onCancel={() => {
                    setOpenAudioModal({ id: '', open: false })
                  }}
                  visible={openAudioModal.open}
                  width={'85%'}
                  footer={
                    <FooterActions
                      t={t}
                      loading={isVoiceLoading}
                      title={t('buttons:insert')}
                      onClickCancel={() => {
                        setOpenAudioModal({ id: '', open: false })
                      }}
                      onClickTitle={() => createVoice(selectedAudio)}
                    />
                  }
                >
                  <Audio
                    isPageBuilder
                    onShowCreateModal={onShowCreateModal}
                    getSelectedFile={getSelectedFile}
                    handleInsertData={handleInsertData}
                    src={''}
                  />
                </Modal>
                <Tabs
                  defaultActiveKey="1"
                  onChange={episodeChanage}
                  activeKey={tabIndex}
                >
                  {allEpisode.length !== 0 &&
                    allEpisode.map((episode, i) => {
                      return (
                        <TabPane
                          tab={`Episode ${i + 1}`}
                          key={JSON.stringify(i + 1)}
                        >
                          <Row gutter={[16, 8]}>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                required={false}
                                colon={false}
                                className="no_bottom_margin"
                              >
                                {getFieldDecorator('cover_id', {
                                  initialValue: formValues.cover.id,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'please upload podcast cover!'
                                    }
                                  ]
                                })(
                                  <ImageSelect
                                    label={'Cover Picture'}
                                    coverInput={
                                      formValues.cover.id
                                        ? formValues.cover
                                        : null
                                    }
                                    onCoverChange={setCoverImage}
                                  />
                                )}
                              </Form.Item>
                              {episode.speakers.map((speaker, i) => {
                                return (
                                  <Row gutter={[16, 8]} key={i}>
                                    <Col xs={12} sm={12} md={10} lg={10}>
                                      <Form.Item
                                        label={
                                          i === 0
                                            ? 'Name of the Host'
                                            : `Name of the Guest No.${i + 1}`
                                        }
                                        colon={false}
                                        required={false}
                                      >
                                        {getFieldDecorator(speaker, {
                                          initialValue: speaker,
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                i === 0
                                                  ? 'Please enter the host name!'
                                                  : 'Please enter the guest name!'
                                            }
                                          ]
                                        })(<Input disabled />)}
                                      </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                      <Form.Item
                                        label="Voice"
                                        colon={false}
                                        required={false}
                                      >
                                        {getFieldDecorator(speaker + '_voice', {
                                          initialValue:
                                            voiceList[0].voice_id || undefined
                                        })(
                                          <Select
                                            size="large"
                                            disabled={isGenerating}
                                          >
                                            {voiceList.map((el, i) => {
                                              return (
                                                <Option
                                                  value={el.voice_id}
                                                  key={i}
                                                >
                                                  {el.name}
                                                </Option>
                                              )
                                            })}
                                          </Select>
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                      <Form.Item
                                        label=""
                                        colon={false}
                                        required={false}
                                      >
                                        <Button
                                          style={{ marginTop: '25px' }}
                                          loading={
                                            isVoiceLoading.id === speaker &&
                                            isVoiceLoading.status
                                          }
                                          disabled={
                                            (isVoiceLoading.id !== speaker &&
                                              isVoiceLoading.status) ||
                                            isGenerating
                                          }
                                          onClick={() => {
                                            setOpenAudioModal({
                                              id: speaker,
                                              open: true
                                            })
                                          }}
                                        >
                                          <PlusCircleOutlined
                                            style={{ color: '#5057D5' }}
                                          />
                                          Library
                                        </Button>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={2} lg={2}>
                                      <Form.Item
                                        label=""
                                        colon={false}
                                        required={false}
                                        style={{ marginTop: '25px' }}
                                      >
                                        <SoundOutlined
                                          onClick={() => {
                                            listenSample(speaker)
                                          }}
                                          style={{
                                            color: '#5057D5',
                                            fontSize: 20,
                                            padding: 6
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                )
                              })}
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                colon={false}
                                required={false}
                                label={'Context'}
                                className="no_bottom_margin"
                              >
                                <div
                                  id="scrollableDiv"
                                  style={{
                                    height: 500,
                                    overflowY: 'auto',
                                    borderRadius: 10,
                                    padding: '1.2rem',
                                    border:
                                      '1px solid rgba(140, 140, 140, 0.35)'
                                  }}
                                >
                                  <List
                                    dataSource={contents}
                                    renderItem={(item, i) => (
                                      <List.Item
                                        key={i}
                                        actions={[
                                          !isGenerating &&
                                            (isEdit.index !== i ||
                                              !isEdit.status) && (
                                              <a
                                                key="list-loadmore-edit"
                                                onClick={() => {
                                                  setIsEdit({
                                                    index: i,
                                                    context: item.context,
                                                    status: true
                                                  })
                                                }}
                                              >
                                                Edit
                                              </a>
                                            ),
                                          !isGenerating &&
                                            isEdit.index === i &&
                                            isEdit.status && (
                                              <a
                                                key="list-loadmore-save"
                                                onClick={() => {
                                                  updateContext(i)
                                                }}
                                              >
                                                Save
                                              </a>
                                            ),
                                          !isGenerating &&
                                            isEdit.index === i &&
                                            isEdit.status && (
                                              <a
                                                key="list-loadmore-cancel"
                                                onClick={() => {
                                                  setIsEdit({
                                                    index: -1,
                                                    context: '',
                                                    status: false
                                                  })
                                                }}
                                              >
                                                Cancel
                                              </a>
                                            )
                                        ].filter(Boolean)}
                                      >
                                        <List.Item.Meta
                                          avatar={
                                            <Avatar
                                              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                                                item.speaker
                                              )}`}
                                            />
                                          }
                                          title={
                                            <div>
                                              <a>{item.speaker}</a>&nbsp;
                                              {/* <SoundOutlined
              onClick={() => {
                listenSample(item.speaker, item.context);
              }}
              style={{
                color: '#5057D5',
                fontSize: 20,
                padding: 6
              }}
            /> */}
                                            </div>
                                          }
                                          description={
                                            isEdit.index === i &&
                                            isEdit.status ? (
                                              <Input.TextArea
                                                rows={3}
                                                defaultValue={isEdit.context}
                                                onChange={e =>
                                                  handleInputChange(e, i)
                                                }
                                              />
                                            ) : (
                                              item.context
                                            )
                                          }
                                        />
                                      </List.Item>
                                    )}
                                  />
                                </div>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <div className="discussion-machine-main-body-action">
                                <div className="generate-btn">
                                  <Tooltip
                                    placement="left"
                                    title={
                                      'This will generate tthe audio of the content according to selected voices.'
                                    }
                                    arrow
                                  >
                                    <span>
                                      <Button
                                        type="primary"
                                        className="rounded"
                                        loading={isGenerating}
                                        onClick={() => {
                                          generateAudio(i)
                                        }}
                                      >
                                        Generate to Voice
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                                <div className="generate-audio">
                                  <ReactPlayer
                                    url={audioUrl.link}
                                    playing={true}
                                    controls={true}
                                    width="100%"
                                    height="50px"
                                    config={{
                                      file: {
                                        forceAudio: true
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      )
                    })}
                </Tabs>
              </Fragment>
            )}
          </Form>
        </div>
        {/* <div className="discussion-machine-main-footer">
          <div className="discussion-machine-main-footer-inner">
            {step === 0 && (
              <Fragment>
                <Tooltip placement="left" title={''} arrow>
                  <span>
                    <Button
                      type="primary"
                      className="rounded"
                      loading={isGenerating}
                      onClick={generateContent}
                      disabled={isUploading}
                    >
                      Generate to text
                    </Button>
                  </span>
                </Tooltip>
              </Fragment>
            )}
            {step === 1 && (
              <Fragment>
                <Tooltip placement="left" title={''} arrow>
                  <span>
                    <Button
                      type="primary"
                      className="rounded"
                      onClick={() => {
                        previous()
                      }}
                      disabled={isGenerating}
                    >
                      <LeftOutlined /> Go Back
                    </Button>
                  </span>
                </Tooltip>
                &nbsp;
                <Tooltip placement="left" title={''} arrow>
                  <span>
                    <Button
                      type="primary"
                      className="rounded"
                      loading={isSaving}
                      disabled={!audioUrl.link}
                      onClick={createPodcastEpishode}
                    >
                      Create Podcast Episode
                    </Button>
                  </span>
                </Tooltip>
              </Fragment>
            )}
          </div>
        </div> */}
      </div>
    </Modal>
  )
}

export default Form.create({ name: 'podcast_form' })(DiscussionCreateMachine)
