import React, { Component } from 'react'
import { isSafari } from 'react-device-detect'
import './Audio.scss'
import { AudioPlayer } from '../../../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Audio/components'
export default class extends Component {
  render() {
    const {
      elementHeight,
      elementWidth,
      node: { params }
    } = this.props

    return (
      <>
        {AudioPlayer({
          width:
            elementWidth && isSafari
              ? elementWidth
              : elementWidth
              ? elementWidth
              : params.width,
          height: elementHeight ? elementHeight : params.height,
          src: params.content
        })}
      </>
    )
  }
}
