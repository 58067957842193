/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-08 11:28:55
 * @LastEditTime: 2019-12-18 13:25:27
 * @LastEditors: Please set LastEditors
 */
import {
  FETCH_COURSES,
  LOAD_COURSES,
  FETCH_COURSE_BY_ID,
  GET_COURSE_BY_ID,
  REMOVE_COURSE_BY_ID,
  RESET_COURSE_BY_ID,
  REMOVE_COURSE_FROM_LIST,
  TRAINING_COURSES_FETCHING,
  TRAINING_COURSES_SUCCESS,
  GET_LESSON_DATA_BY_ID,
  FETCH_LESSON_DATA_BY_ID,
  ENROLLED_COURSES_FETCHING,
  ENROLLED_COURSES_SUCCESS,
  ENROLL_COURSE_FETCHING,
  ENROLL_COURSE_SUCCESS,
  UNENROLL_COURSE_FETCHING,
  UNENROLL_COURSE_SUCCESS,
  DASHBOARD_COURSES_FETCHING,
  DASHBOARD_COURSES_SUCCESS,
  VIEW_COURSE_SUCCESS,
  WORKSHOP_COURSES_FETCHING,
  WORKSHOP_COURSES_SUCCESS,
  COURSE_CLOINING_PROGRESS,
  USER_COURSES_FETCHING,
  USER_COURSES_SUCCESS,
  SHOW_POP_OVER,
  LOAD_SCORM_COURSE_BY_ID,
  GET_SCORM_COURSE_BY_ID
} from '../types'
import { config } from '../../constants'

import api from '../../services/api'

let socket = null

export const fetchCourses = (list, params) => ({
  type: FETCH_COURSES,
  list,
  params
})

export const loadCourses = (list, fetch, hasMoreItems) => ({
  type: LOAD_COURSES,
  list,
  fetch,
  hasMoreItems
})

export const removeCourseById = id => ({
  type: REMOVE_COURSE_BY_ID,
  id
})

export const removeCourseFromList = id => ({
  type: REMOVE_COURSE_FROM_LIST,
  id
})

export const getLessonById = id => dispatch => {
  dispatch({
    type: FETCH_LESSON_DATA_BY_ID,
    payload: true
  })

  return api.lessons.getLessonById(id).then(({ data }) => {
    if (data) {
      dispatch({
        type: GET_LESSON_DATA_BY_ID,
        payload: data
      })

      dispatch({
        type: FETCH_LESSON_DATA_BY_ID,
        payload: false
      })

      return data
    }
  })
}

export const initCloneSocket = ({ courseId, user }) => dispatch => {
  const userId = user.info.id || ''
  // const options = `channel_type=CLONE_OURESE&channel_id=${courseId}&user_id=${userId}`
  const options = `channel_type=CLONE_COURSE&channel_id=CLONE-COURSE-${courseId}-USERID-${userId}&user_id=${userId}`
  socket = new window.WebSocket(`${config.socketURL}?${options}`)

  socket.onopen = data => {
    console.log('Connected to course ' + courseId, user)
  }

  socket.onmessage = event => {
    const data = JSON.parse(event.data)
    console.log('coursemessage', event, data)
    const newCompleted = data.completed
    data.percent = (newCompleted / data.total) * 100

    if (data.target_user === user.info.id) {
      dispatch({
        type: COURSE_CLOINING_PROGRESS,
        payload: data
      })
    }

    if (data && data.total && data.completed && data.total === data.completed) {
      socket.onclose = function() {} // disable onclose handler first
      socket.close()
      //   // if (socket.readyState === window.WebSocket.OPEN) {
      //   //   socket.close(100, 'Socket DC')
      //   // }
      //   setTimeout(() => {
      //   }, timeout);
      //   dispatch({
      //     type: COURSE_CLOINING_PROGRESS,
      //     payload: null
      //   })
    }
  }
}

export const clearCourseCloning = () => dispatch => {
  dispatch({
    type: COURSE_CLOINING_PROGRESS,
    payload: null
  })
}

export const showPopover = data => dispatch => {
  dispatch({
    type: SHOW_POP_OVER,
    payload: data
  })
}

export const getCourseById = id => dispatch => {
  dispatch({
    type: FETCH_COURSE_BY_ID,
    payload: true
  })

  return api.courses
    .getCourseById(id)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_COURSE_BY_ID,
          payload: data
        })

        dispatch({
          type: FETCH_COURSE_BY_ID,
          payload: false
        })

        return data
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_COURSE_BY_ID,
        payload: false
      })

      throw error
    })
}

export const getScormByCourseId = id => dispatch => {
  dispatch({
    type: LOAD_SCORM_COURSE_BY_ID,
    payload: true
  })

  return api.scorm
    .getScormByCourseId(id)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_SCORM_COURSE_BY_ID,
          payload: data
        })

        dispatch({
          type: LOAD_SCORM_COURSE_BY_ID,
          payload: false
        })

        return data
      }
    })
    .catch(() => {
      dispatch({
        type: FETCH_COURSE_BY_ID,
        payload: false
      })
    })
}

export const resetCourseById = () => dispatch => {
  dispatch({
    type: RESET_COURSE_BY_ID,
    payload: null
  })
}

export const getTrainingCourses = params => dispatch => {
  dispatch({
    type: TRAINING_COURSES_FETCHING,
    payload: true
  })

  return api.courses
    .getCoursesList({ mode: 'training', ...params })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: TRAINING_COURSES_SUCCESS,
          payload: data
        })

        dispatch({
          type: TRAINING_COURSES_FETCHING,
          payload: false
        })

        return data
      }
    })
}

export const getEnrolledCourses = params => dispatch => {
  dispatch({
    type: ENROLLED_COURSES_FETCHING,
    payload: true
  })

  return api.courses
    .getCoursesList({ mode: 'enrollment', ...params })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: ENROLLED_COURSES_SUCCESS,
          payload: data
        })

        dispatch({
          type: ENROLLED_COURSES_FETCHING,
          payload: false
        })

        return data
      }
    })
}

export const enrollCourse = (courseId, lessonId, pageUid) => (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    courseById: { item: course },
    isCourseEnrollment
  } = state.courses

  if (isCourseEnrollment) {
    setTimeout(() => {
      dispatch(enrollCourse(courseId, lessonId, pageUid))
    }, 1000)
    return
  }

  const currentProgress = (course.enrollment || {}).progress || {}

  let updatedProgress = {}
  let progress = {}

  if (lessonId) {
    progress = currentProgress[lessonId]
    if (progress && progress.includes(pageUid)) return

    updatedProgress = {
      ...currentProgress,
      [lessonId]:
        progress && progress.length > 0 ? [...progress, pageUid] : [pageUid]
    }
  }

  dispatch({ type: ENROLL_COURSE_FETCHING, payload: true })

  const apiPromise = api.courses.courseEnroll(courseId, {
    ...currentProgress,
    ...(lessonId && {
      [lessonId]:
        progress && progress.length > 0 ? [...progress, pageUid] : [pageUid]
    })
  })

  return apiPromise
    .then(({ data }) => {
      if (!data) {
        throw new Error('No data received from the server')
      }

      dispatch({ type: ENROLL_COURSE_SUCCESS, payload: updatedProgress })
      dispatch({ type: ENROLL_COURSE_FETCHING, payload: false })

      return data
    })
    .catch(error => {
      console.error('Error enrolling in course:', error)
      dispatch({ type: ENROLL_COURSE_FETCHING, payload: false })
      throw error
    })
}

export const unenrollCourse = courseId => dispatch => {
  dispatch({
    type: UNENROLL_COURSE_FETCHING,
    payload: true
  })

  return api.courses.courseUnenroll(courseId).then(({ data }) => {
    if (data) {
      dispatch({
        type: UNENROLL_COURSE_SUCCESS
      })

      dispatch({
        type: UNENROLL_COURSE_FETCHING,
        payload: false
      })

      return data
    }
  })
}

export const getDashboardCourses = params => dispatch => {
  dispatch({
    type: DASHBOARD_COURSES_FETCHING,
    payload: true
  })

  return api.courses
    .getCoursesList({ mode: 'dashboard', ...params })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: DASHBOARD_COURSES_SUCCESS,
          payload: data
        })

        dispatch({
          type: DASHBOARD_COURSES_FETCHING,
          payload: false
        })

        return data
      }
    })
}

export const getWorkshopCourses = params => (dispatch, getState) => {
  const state = getState()

  dispatch({
    type: WORKSHOP_COURSES_FETCHING,
    payload: true
  })

  return api.courses
    .getCoursesList({ mode: 'workshop', ...params })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: WORKSHOP_COURSES_SUCCESS,
          payload: (params.offset
            ? state.courses.workshopCourses.list
            : []
          ).concat(data)
        })

        dispatch({
          type: WORKSHOP_COURSES_FETCHING,
          payload: false
        })

        return data
      }
    })
}

export const getCoursesByUser = params => (dispatch, getState) => {
  const state = getState()

  dispatch({
    type: USER_COURSES_FETCHING,
    payload: true
  })

  return api.courses
    .getCoursesList({ mode: 'all', ...params })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: USER_COURSES_SUCCESS,
          payload: (params.offset
            ? state.courses.workshopCourses.list
            : []
          ).concat(data)
        })

        dispatch({
          type: USER_COURSES_FETCHING,
          payload: false
        })

        return data
      }
    })
}

export const viewCourse = courseId => dispatch => {
  return api.courses.viewCourse(courseId).then(({ data }) => {
    if (data) {
      dispatch({
        type: VIEW_COURSE_SUCCESS
      })
    }
  })
}
