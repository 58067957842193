export const flattenTree = treeObj => {
  let flatten = (children, getChildren, level, parent) =>
    Array.prototype.concat.apply(
      children.map(x => ({ ...x, level: level || 1, parent: parent || null })),
      children.map(x =>
        flatten(getChildren(x) || [], getChildren, (level || 1) + 1, x.code)
      )
    )
  let extractChildren = x => x.children
  let flat = flatten(extractChildren(treeObj), extractChildren).map(
    x => delete x.children && x
  )
  return flat
}

export const getNestedChildren = (list, codeAttr, parentAttr, childrenAttr) => {
  if (!codeAttr) codeAttr = 'code'
  if (!parentAttr) parentAttr = 'parent'
  if (!childrenAttr) childrenAttr = 'children'

  console.log('List', list)

  var treeList = []
  var lookup = {}
  list.forEach(obj => {
    lookup[obj[codeAttr]] = obj
    obj[childrenAttr] = []
  })
  list.forEach(obj => {
    if (obj[parentAttr] !== null) {
      if (lookup[obj[parentAttr]] !== undefined) {
        lookup[obj[parentAttr]][childrenAttr].push(obj)
      } else {
        //console.log('Missing Parent Data: ' + obj[parentAttr]);
        treeList.push(obj)
      }
    } else {
      treeList.push(obj)
    }
  })
  return treeList
}
