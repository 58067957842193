import React, { Fragment, useTransition } from 'react'
import {
  ProgressBar,
  Reviews,
  ReviewsButtonModal
} from '../../../../../components'
import { Head, Tab } from '../../../../../styles'
import { Lessons } from '../../../../../styles/blocks'

import {
  checkCover,
  checkActiveLesson,
  getThumbnail,
  getLessonItemClassName
} from '../Controller'
import {
  PanelItem,
  LessonsBlock,
  Btn,
  CourseOwner,
  CourseContent
} from '../../styled'
import '../../styles.scss'
import Discussion from '../../../../../components/Discussion'
import Notes from '../../../../../components/Notes'
import triangleImg from '../../img/triangle.svg'
import chatIcon from '../../img/chat.svg'
import ReactSVG from 'react-svg'
import { useFetchReviews } from '../../../../../components/Reviews/Reviews'
import { Alert, Icon } from 'antd'
import pdfImage from '../../../../../assets/pdf_image.png'
import { useTranslation } from 'react-i18next'
import { marginLeft } from 'styled-system'
// import { Switch } from 'antd'

const PanelCourse = (title, description, cover) => (
  <Fragment>
    <PanelItem>
      <PanelItem.Title>{title}</PanelItem.Title>
      <PanelItem.Content>
        <PanelItem.Description>{description}</PanelItem.Description>
        <PanelItem.Image src={checkCover(cover)} />
      </PanelItem.Content>
    </PanelItem>
  </Fragment>
)

const PanelLesson = (
  App,
  activeLesson,
  enrollment,
  isOwner,
  isSuperAdmin,
  activeLessonId,
  t
) => (
  <Fragment>
    <PanelItem>
      <Head>
        <PanelItem.Wrapper>
          <PanelItem.Title>{activeLesson.title}</PanelItem.Title>
          {(enrollment || isOwner || isSuperAdmin) && (
            <Btn
              startlesson="true"
              type="primary"
              className="rounded"
              onClick={() => App.openLessonContent(activeLessonId)}
            >
              <img src={triangleImg} alt="triangle" />
              {t('buttons:start_lesson')}
            </Btn>
          )}
        </PanelItem.Wrapper>
      </Head>
      <PanelItem.Content>
        <PanelItem.Description>
          {activeLesson.description}
        </PanelItem.Description>
      </PanelItem.Content>
      <PanelItem.Image src={checkActiveLesson(activeLesson)} />
    </PanelItem>
  </Fragment>
)

const LessonSection = (
  App,
  lessons,
  activeLessonId,
  t,
  downloadingLessonId,
  isSuperAdmin
) => (
  <Fragment>
    <LessonsBlock>
      <Lessons>
        <Lessons.Count>
          {lessons.length} {t('general:lessons')}
        </Lessons.Count>
        <Lessons.List>
          {lessons.map((lesson, index) => {
            const { id, cover, title } = lesson
            const img = getThumbnail(cover)

            const percent = App.getPercent(lesson)

            return (
              <Lessons.Item
                key={id}
                onClick={() => App.handleChangeLesson(id)}
                className={getLessonItemClassName(id, activeLessonId)}
                style={{ flexDirection: 'column' }}
              >
                <div className="wrap-lesson-info">
                  <Lessons.ItemNumber>{index + 1}</Lessons.ItemNumber>

                  <Lessons.ItemCover cover={img} />

                  <Lessons.ItemTitle>{title}</Lessons.ItemTitle>

                  {isSuperAdmin && (
                    <Icon
                      type={downloadingLessonId === id ? 'loading' : 'download'}
                      style={{
                        cursor:
                          downloadingLessonId && downloadingLessonId !== id
                            ? 'not-allowed'
                            : 'pointer',
                        marginLeft: 'auto'
                      }}
                      onClick={() =>
                        downloadingLessonId ? null : App.downloadLesson(id)
                      }
                    />
                  )}
                </div>

                {!!percent && (
                  <ProgressBar
                    percent={percent}
                    style={{ marginTop: '1rem' }}
                  />
                )}
              </Lessons.Item>
            )
          })}
        </Lessons.List>
      </Lessons>
    </LessonsBlock>
  </Fragment>
)

const CourseSection = ({ App, isOwner }) => {
  const {
    course: {
      item: {
        title,
        cover,
        description,
        courses_lessons: lessons = [],
        enrollment,
        attachment_details = [],
        id
      }
    },
    t,
    user: {
      info: { id: userId, isSuperAdmin }
    },
    log
  } = App.props
  const { activeTab, activeLessonId, downloadingLessonId } = App.state
  const activeLesson = lessons.find(i => i.id === activeLessonId)

  let unreadDiscussions = 0
  if (log && log[0]) {
    unreadDiscussions +=
      log[0].summary.current_messages - log[0].userLog.messages
    unreadDiscussions += log[0].summary.current_replies - log[0].userLog.replies
    unreadDiscussions += log[0].summary.current_votes - log[0].userLog.votes
  }
  useFetchReviews(id, 'course')
  console.log('course attachment', attachment_details)
  return (
    <Fragment>
      <CourseContent>
        <Tab
          className="course-tab"
          activeKey={activeTab}
          animated={false}
          onChange={App.handleChangeTab}
          style={{ position: 'relative' }}
          tabBarExtraContent={
            <ReviewsButtonModal id={id} userId={userId} type="course" />
          }
        >
          <Tab.Panel tab={t('tabs:course')} key="course_overview">
            {PanelCourse(title, description, cover)}
          </Tab.Panel>

          <Tab.Panel tab={t('tabs:lessons')} key="lessons">
            {activeLesson &&
              PanelLesson(
                App,
                activeLesson,
                enrollment,
                isOwner,
                isSuperAdmin,
                activeLessonId,
                t
              )}
          </Tab.Panel>
          <Tab.TabPane
            tab={`${t('tabs:discussion')} (${log &&
              log[0] &&
              log[0].summary.current_messages})`}
            key="discussion"
          >
            <Discussion
              discussionId={id}
              discussionType={'course'}
              userId={userId}
            />
          </Tab.TabPane>
          <Tab.TabPane tab={t('tabs:notes')} key="notes">
            <Notes noteId={id} noteType={'course'} userId={userId} />
          </Tab.TabPane>
          <Tab.TabPane tab={t('v4:reviews')} key="reviews">
            <Reviews id={id} userId={userId} type="courses" />
          </Tab.TabPane>
          <Tab.TabPane tab={t('v4:tab_attachments')} key="attachments">
            <Attachments attachments={attachment_details} />
          </Tab.TabPane>
          <div className="card__wrapper" tab={''} key="log">
            {unreadDiscussions > 0 && (
              <div className="card__log_wrapper">
                <ReactSVG src={chatIcon} className="card__log" />
                <span className="card__log_unread">
                  {unreadDiscussions >= 10 ? '9+' : unreadDiscussions}
                </span>
              </div>
            )}
          </div>
        </Tab>

        {lessons &&
          LessonSection(
            App,
            lessons,
            activeLessonId,
            t,
            downloadingLessonId,
            isSuperAdmin
          )}
      </CourseContent>
    </Fragment>
  )
}

const CourseOwnerSection = ({ organiser, t }) => (
  <Fragment>
    <CourseOwner>
      <CourseOwner.Name>
        {t('general:by')}&nbsp;
        <span>{organiser}</span>
      </CourseOwner.Name>
    </CourseOwner>
  </Fragment>
)

const HeaderSection = ({ title, topics }) => (
  <section className="section" style={{ paddingBottom: '0' }}>
    <h2>{title}</h2>
    <h4>{topics}</h4>
  </section>
)

const Attachments = ({ attachments }) => {
  const download = (url, filename) => {
    fetch(url).then(t => {
      return t.blob().then(b => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', filename)
        a.click()
      })
    })
  }

  const { t } = useTranslation()

  return (
    <Fragment>
      {attachments && attachments.length > 0 ? (
        attachments.map(item => (
          <div
            className="file-upload__item"
            style={{ borderTop: '2px solid rgb(230, 229, 229)' }}
          >
            <div className="file-upload__item-info">
              <img
                className="file-upload__item-info-image"
                src={pdfImage}
                alt=""
              />
              <span>{item.title}</span>
            </div>
            <div className="file-upload__item-action">
              <span onClick={() => window.open(item.link, '_blank').focus()}>
                {t('v3:view')}
              </span>

              <Icon
                className="file-upload__item-action-delete"
                type="download"
                onClick={() => download(item.link, item.title)}
              />
            </div>
          </div>
        ))
      ) : (
        <Alert message={t('v4:no_attachment')} />
      )}
    </Fragment>
  )
}

export {
  PanelCourse,
  PanelLesson,
  LessonSection,
  CourseSection,
  HeaderSection,
  CourseOwnerSection
}
