import React, { useState } from 'react'
import { connect } from 'react-redux'
import { message, Progress } from 'antd'
import { bindActionCreators } from 'redux'
import {
  UploadOutlined,
  EyeOutlined,
  CloseOutlined,
  SyncOutlined
} from '@ant-design/icons'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import './UploadFiles.scss'
import attachIcon from './attach.svg'
import pdfIcon from './pdfIcon.svg'
import actions from '../../../../../store/actions'
const UploadFiles = ({
  library,
  setData,
  data,
  upload,
  clearUploadList,
  setIsLoading
}) => {
  const { t } = useTranslation()
  // useEffect(() => {
  //   if (ids.length) {
  //     getAttachmentDetail(ids)
  //   }
  // }, [ids])
  const [isUploading, setIsUploading] = useState(false)

  const handleView = link => {
    window.open(link, '_blank').focus()
  }

  const handleRemove = selectedId => {
    const updatedAttachmentIds = data.attachmentIds.filter(
      id => id !== selectedId
    )
    const updatedFilenames = data.filenames.filter(
      file => file.id !== selectedId
    )
    setData({
      ...data,
      attachmentIds: updatedAttachmentIds,
      filenames: updatedFilenames
    })
  }
  const onDropFile = async dropped => {
    clearUploadList()
    if (!dropped || dropped.length === 0) {
      return
    }
    const maxSize = 25 * 1024 * 1024
    const invalidFiles = dropped.filter(file => file.size > maxSize)

    if (invalidFiles.length > 0) {
      message.error('File size must be lower then 25 MB!')
      return
    }
    setIsUploading(true)
    setIsLoading(true)

    let uploadedFile = []
    await Promise.all(
      dropped.map(async file => {
        const response = await upload(file)
        uploadedFile.push(response)
      })
    )
    const newAttachmentIds = uploadedFile.map(file => file.id)
    const allIds = [...new Set(data.attachmentIds.concat(newAttachmentIds))] // Remove duplicates

    const allFilenames = [...data.filenames, ...uploadedFile].filter(
      (v, i, arr) => arr.findIndex(t => t.id === v.id) === i // Keep unique filenames based on `id`
    )
    clearUploadList()
    setData({
      ...data,
      attachmentIds: allIds,
      filenames: allFilenames
    })
    setTimeout(() => {
      setIsUploading(false)
      setIsLoading(false)
    }, 500)
  }
  return (
    <div className="upload-file-main">
      <Dropzone
        accept={[
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]}
        multiple={true}
        onDrop={onDropFile}
        style={{ width: '100%' }}
        disabled={isUploading}
      >
        {() => {
          return (
            <div className="upload-file-main-action">
              <div className="upload-file-main-action-right">
                <div className="upload-file-main-action-right-icon">
                  <img src={attachIcon} alt="attachIcon" />
                </div>
                <div className="upload-file-main-action-right-content">
                  <div className="upload-file-main-action-right-content-title">
                    Add File
                  </div>
                  <div className="upload-file-main-action-right-content-size">
                    25 MB, maximum file size
                  </div>
                </div>
              </div>

              <div className="upload-file-main-action-btn">
                {isUploading ? (
                  <SyncOutlined
                    spin
                    style={{ color: '#5B6EE2', fontSize: 16 }}
                  />
                ) : (
                  <UploadOutlined style={{ color: '#5B6EE2', fontSize: 16 }} />
                )}
              </div>
            </div>
          )
        }}
      </Dropzone>
      <div>
        {data.filenames.map((file, i) => {
          return (
            <div>
              <div className="upload-file-main-filediv">
                <div className="upload-file-main-filediv-right">
                  <div className="upload-file-main-filediv-right-icon">
                    <img src={pdfIcon} alt="pdfIcon" />
                  </div>
                  <div className="upload-file-main-filediv-right-content">
                    <div>
                      <div className="upload-file-main-filediv-right-content-title">
                        {file.filename}
                      </div>
                      <div className="upload-file-main-filediv-right-content-size">
                        {parseInt(file.file_size) > 1000
                          ? parseFloat(file.file_size / 1024).toFixed(2) + ' KB'
                          : parseInt(file.file_size) / 1024 + ' KB'}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="upload-file-main-filediv-btn">
                  <div>
                    <EyeOutlined
                      style={{ color: '#5B6EE2', fontSize: 16 }}
                      onClick={() => {
                        handleView(file.link)
                      }}
                    />
                  </div>
                  <div>
                    <CloseOutlined
                      style={{ color: 'red', fontSize: 16 }}
                      onClick={() => {
                        handleRemove(file.id)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}

        {isUploading &&
          library.uploadProgress.map((file, i) => {
            return (
              <div>
                <div className="upload-file-main-filediv">
                  <div className="upload-file-main-filediv-right">
                    <div className="upload-file-main-filediv-right-icon">
                      <img src={pdfIcon} alt="pdfIcon" />
                    </div>
                    <div className="upload-file-main-filediv-right-content">
                      <div>
                        <div className="upload-file-main-filediv-right-content-title">
                          {file.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {file.percent > 0 && <Progress percent={file.percent} />}
              </div>
            )
          })}
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  library: state.library
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(UploadFiles)
