import React, { Component } from 'react'
import { Popconfirm, Spin, Pagination, Modal, Icon, Button } from 'antd'
import _ from 'lodash'
import { Page, Column, Table, Row } from './styled'
import { ModalAddOrganisations } from './internal'

import { Spinner, Search } from '../../../../components'

import { pencil, trash } from '../../images'
import { withTranslation } from 'react-i18next'
import './styles.scss'

const filterSort = [
  { value: 'asc', text: 'filters:ASC' },
  { value: 'desc', text: 'filters:DESC' }
]

class Organisations extends Component {
  constructor(props) {
    super(props)
    this.getOrganizationsList()
  }

  state = {
    isModalVisible: false,
    isModalUpdateLiveStream: false,
    isConfirmLoading: false,
    editData: null,
    isCreation: false,
    value: '',
    isEditing: false,
    sort: filterSort[0].value,
    deletedOrgId: null,
    activeOrg: null,
    page: 1,
    pageSize: 20
  }
  createOffset = (page, pageSize) => page * pageSize - pageSize

  componentDidMount() {}

  // onSearch = val => {
  //   const search = val.target.value
  //   if (_.isEmpty(search)) {
  //     // this.props.resetUsersByOrganizationId()
  //   }
  //   this.setState({
  //     value: search,
  //     isLoading: true
  //   })
  // }

  onSearch = _.debounce(
    val =>
      this.setState(
        {
          value: val
          // page: 1
        },
        () => {
          const { getOrganizations } = this.props
          const data = {
            search: val
          }
          getOrganizations(data)
        }
      ),
    1000
  )

  getOrganizationsList = () => {
    const { getOrganizations } = this.props
    const { page, pageSize, sort } = this.state
    const data = {
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      sort: sort
    }
    getOrganizations(data)
  }

  setModalVisible = value => {
    this.setState({
      isModalVisible: value
    })
  }

  cancel = () => {
    this.setState({
      isCreation: false,
      editData: null,
      isModalVisible: false,
      activeOrg: null
    })
  }

  create = data => {
    const {
      getOrganizations,
      createOrganization,
      updateOrganizationsList
    } = this.props
    const { page, pageSize } = this.state
    const params = {
      offset: this.createOffset(page, pageSize),
      limit: pageSize
    }
    this.setState({
      isCreation: true
    })
    createOrganization(data).then(() => {
      this.setState({
        isModalVisible: false,
        isCreation: false,
        activeOrg: null
      })
      updateOrganizationsList()
      return getOrganizations(params)
    })
  }

  update = data => {
    const {
      getOrganizations,
      updateOrganization,
      updateOrganizationsList
    } = this.props
    const { activeOrg } = this.state
    const { page, pageSize } = this.state
    const params = {
      offset: this.createOffset(page, pageSize),
      limit: pageSize
    }
    this.setState({
      isEditing: true
    })
    return updateOrganization(activeOrg.id, data).then(() => {
      this.setState({
        isModalVisible: false,
        isModalUpdateLiveStream: false,
        isConfirmLoading: false,
        isEditing: false,
        activeOrg: null,
        editData: null
      })
      updateOrganizationsList()
      return getOrganizations(params)
    })
  }

  checkStreamLimit = data => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    if (isSuperAdmin) {
      this.setState({
        isModalUpdateLiveStream: true,
        isModalVisible: false,
        editData: data
      })
    } else {
      this.update(data)
    }
  }

  onConfirmModal = () => {
    this.setState({
      isConfirmLoading: true
    })
    this.update(this.state.editData)
  }
  onCancelModal = () => {
    this.setState({
      isModalUpdateLiveStream: false,
      isModalVisible: true
    })
  }

  deleteOrgById = (e, id) => {
    const {
      user: { info },
      updateOrganizationsList
    } = this.props

    e.stopPropagation()
    this.setState({
      deletedOrgId: id
    })
    this.props
      .deleteOrganizationById(id)
      .then(() => {
        this.setState({
          deletedOrgId: null
        })
      })
      .then(() => {
        this.getOrganizationsList()
        updateOrganizationsList()
        if (info.active_organization_id === id) {
          window.location.reload()
        }
      })
  }

  editOrg = activeOrg => {
    this.setState({
      activeOrg,
      isModalVisible: true
    })
  }

  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
        isFetching: true
      },
      this.getOrganizationsList
    )
  }

  render() {
    const {
      isModalVisible,
      isCreation,
      deletedOrgId,
      activeOrg,
      isEditing,
      pageSize,
      sort,
      page,
      isModalUpdateLiveStream,
      isConfirmLoading,
      editData
    } = this.state

    let {
      organizations: {
        autoComplete: { rows: list, count },
        isFetching
      },
      user: {
        info: { isSuperAdmin, isAdmin }
      },
      t,
      history
    } = this.props

    list = _.orderBy(list, org => org['title'], sort)
    let searchedList = [...list]

    // if (!_.isEmpty(value)) {
    //   searchedList = list.filter(org => {
    //     return org.title.toLowerCase().includes(value.toLowerCase())
    //   })
    // }

    // console.log(`this.props`, this.props)
    // console.log(`this.state`, this.state)
    console.log(`searchedList`, searchedList)
    console.log('searrch', this.state)
    return (
      <Page className="sharelook-report-management">
        <Page.Head>
          <Column>
            <h2>{t('organizations_title')}</h2>
            <h3>{t('organizations_subtitle')}</h3>
            <Page.Head>
              <Column>
                <Row></Row>
              </Column>
            </Page.Head>
          </Column>

          <Column>
            <Row>
              <Search
                placeholder={t('placeholders:search')}
                onChange={e => this.onSearch(e.target.value)}
              />
              {(isSuperAdmin || isAdmin) && (
                <Page.Head.Button
                  type="primary"
                  className="rounded"
                  onClick={() => history.push('/management/usage-report')}
                >
                  {t('v3:usage_report')}
                </Page.Head.Button>
              )}
              {isSuperAdmin && (
                <Page.Head.Button
                  type="primary"
                  className="rounded"
                  onClick={() => this.setModalVisible(true)}
                >
                  {t('buttons:add_organization')}
                </Page.Head.Button>
              )}

              {isModalVisible && (
                <ModalAddOrganisations
                  visible={isModalVisible}
                  onCancel={() => this.cancel()}
                  isEditing={isEditing}
                  onSubmit={(data, isEdit) =>
                    // isEdit ? this.update(data) : this.create(data)
                    isEdit ? this.checkStreamLimit(data) : this.create(data)
                  }
                  isSuperAdmin={isSuperAdmin}
                  loading={isCreation || isEditing}
                  org={activeOrg}
                  editData={editData}
                />
              )}

              {console.log(`editData`, editData)}
              {console.log(`activeOrg`, activeOrg)}

              {isModalUpdateLiveStream && (
                <Modal
                  className="modal-stream"
                  visible={isModalUpdateLiveStream}
                  closable={false}
                  footer={null}
                  centered
                >
                  <div className="stream-modal-title">
                    {t('labels:title_modal_stream')}
                  </div>
                  <div className="stream-modal-detail add-space-top">
                    {t('labels:des_warning_stream')} {activeOrg.title}'s
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    {t('labels:monthly_stream')}
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    <u>
                      <strong>{activeOrg.broadcast_limit}</strong>
                    </u>
                    &nbsp;&nbsp;
                    {t('labels:min_per_month')}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <u>
                      <strong>{editData.broadcast_limit}</strong>
                    </u>
                    &nbsp;&nbsp;
                    {t('labels:min_per_month')}
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    {t('v4:monthly_transcribe')}
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    <u>
                      <strong>{activeOrg.transcribe_limit}</strong>
                    </u>
                    &nbsp;&nbsp;
                    {t('labels:min_per_month')}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <u>
                      <strong>{editData.transcribe_limit}</strong>
                    </u>
                    &nbsp;&nbsp;
                    {t('labels:min_per_month')}
                  </div>

                  <div className="stream-modal-detail add-space-bottom">
                    {t('v4:enable_ai')}
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    <u>
                      <strong>{activeOrg.enable_ai ? 'YES' : 'NO'}</strong>
                    </u>
                    &nbsp;&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <u>
                      <strong>{editData.enable_ai ? 'YES' : 'NO'}</strong>
                    </u>
                  </div>

                  <div className="stream-modal-detail add-space-bottom">
                    {t('v4:enable_maze')}
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    <u>
                      <strong>{activeOrg.enable_maze ? 'YES' : 'NO'}</strong>
                    </u>
                    &nbsp;&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <u>
                      <strong>{editData.enable_maze ? 'YES' : 'NO'}</strong>
                    </u>
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    {t('v4:enable_marketplace')}
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    <u>
                      <strong>
                        {activeOrg.is_marketplace_enabled ? 'YES' : 'NO'}
                      </strong>
                    </u>
                    &nbsp;&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <u>
                      <strong>
                        {editData.is_marketplace_enabled ? 'YES' : 'NO'}
                      </strong>
                    </u>
                  </div>

                  <div className="stream-modal-detail add-space-bottom">
                    Enable Course Creation with AI
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    <u>
                      <strong>
                        {activeOrg.enable_ai_course ? 'YES' : 'NO'}
                      </strong>
                    </u>
                    &nbsp;&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <u>
                      <strong>
                        {editData.enable_ai_course ? 'YES' : 'NO'}
                      </strong>
                    </u>
                  </div>

                  <div className="stream-modal-detail add-space-bottom">
                    {t('v4:media_service')}
                  </div>
                  <div className="stream-modal-detail add-space-bottom">
                    <u>
                      <strong>{activeOrg.media_service}</strong>
                    </u>
                    &nbsp;&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <u>
                      <strong>{editData.media_service}</strong>
                    </u>
                  </div>

                  <div className="stream-modal-button">
                    <Button
                      shape="round"
                      className="button-stream"
                      onClick={this.onCancelModal}
                    >
                      {t('buttons:cancel')}
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={this.onConfirmModal}
                      loading={isConfirmLoading}
                    >
                      {t('buttons:confirm')}
                    </Button>
                  </div>
                </Modal>
              )}
            </Row>
          </Column>
        </Page.Head>

        {isFetching ? (
          <Spinner />
        ) : (
          <Table>
            <Table.Header>
              <Table.Label>{t('labels:organizations_list')}</Table.Label>

              <Page.Filter
                data={filterSort}
                defaultValue={filterSort[0].text}
                callback={item => this.setState({ sort: item.value })}
                label={t('labels:order')}
                noIndent
              />
            </Table.Header>

            <Table.Body>
              <Table.Head>
                <Table.Head.Column>{t('v4:id_number')}</Table.Head.Column>
                <Table.Head.Column>{t('labels:name')}</Table.Head.Column>
              </Table.Head>

              {!_.isEmpty(searchedList) &&
                searchedList.map(organisation => (
                  <Spin
                    spinning={deletedOrgId && deletedOrgId === organisation.id}
                    key={organisation.id}
                  >
                    <Table.Item>
                      <Table.Column>
                        <Table.Item.Info>
                          <Table.Item.Organisation>
                            {organisation.id}
                          </Table.Item.Organisation>
                        </Table.Item.Info>
                      </Table.Column>
                      <Table.Column>
                        <Table.Item.Info>
                          <Table.Item.Organisation>
                            {organisation.title}
                          </Table.Item.Organisation>
                        </Table.Item.Info>
                      </Table.Column>

                      <Table.Events>
                        {(isSuperAdmin ||
                          (organisation.role &&
                            organisation.role.name === 'Admin')) && (
                          <Table.EventLink>
                            <Table.EventIcon
                              src={pencil}
                              onClick={() => this.editOrg(organisation)}
                            />
                          </Table.EventLink>
                        )}

                        {isSuperAdmin && (
                          <Popconfirm
                            title={t('delete_warning')}
                            okText={t('general:yes')}
                            cancelText={t('general:no')}
                            placement="right"
                            onClick={e => e.stopPropagation()}
                            onCancel={e => e.stopPropagation()}
                            onConfirm={e =>
                              this.deleteOrgById(e, organisation.id)
                            }
                            icon={false}
                          >
                            <Table.EventLink>
                              <Table.EventIcon src={trash} />
                            </Table.EventLink>
                          </Popconfirm>
                        )}
                      </Table.Events>
                    </Table.Item>
                  </Spin>
                ))}
            </Table.Body>
            <div style={{ paddingTop: '2rem' }}>
              <Pagination
                total={count}
                pageSize={pageSize}
                current={page}
                onChange={this.onChangePage}
              />
            </div>
          </Table>
        )}
      </Page>
    )
  }
}

export default withTranslation('management')(Organisations)
